// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-RecipeCard-styles-module__recipe-card--3pkeq{position:relative;display:flex;flex-flow:column;width:450px;overflow:hidden;font-size:1rem;background-color:#FCE4EC;color:#546E7A}a.src-RecipeCard-styles-module__recipe-card-link--3VCKX{display:flex;text-decoration:none}\n", "",{"version":3,"sources":["webpack://./src/RecipeCard/styles.module.scss"],"names":[],"mappings":"AAAA,kDACE,iBAAiB,CACjB,YAAY,CACZ,gBAAgB,CAChB,WAAY,CACZ,eAAgB,CAEhB,cAAe,CACf,wBAAwB,CACxB,aAAa,CACd,wDAGC,YAAY,CACZ,oBAAoB","sourcesContent":[".recipe-card{\n  position:relative;\n  display:flex;\n  flex-flow:column;\n  width: 450px;\n  overflow: hidden;\n\n  font-size: 1rem;\n  background-color:#FCE4EC;\n  color:#546E7A;\n}\n\na.recipe-card-link{\n  display:flex;\n  text-decoration:none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"recipe-card": "src-RecipeCard-styles-module__recipe-card--3pkeq",
	"recipe-card-link": "src-RecipeCard-styles-module__recipe-card-link--3VCKX"
};
export default ___CSS_LOADER_EXPORT___;
