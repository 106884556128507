// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-Card-styles-module__card--1s_T3{position:relative;max-width:100%;width:250px;min-width:250px;min-height:350px;display:flex;flex-flow:column;background-color:#ecf0f1;border-radius:0.25rem;margin:1rem;user-select:none;transition:0.25s}.src-Card-styles-module__card--1s_T3.src-Card-styles-module__float--1OVtR:hover{transform:translateY(-8px);box-shadow:0 10px 10px -10px #7f8c8d}\n", "",{"version":3,"sources":["webpack://./src/Card/styles.module.scss"],"names":[],"mappings":"AAAA,qCACE,iBAAkB,CAClB,cAAe,CACf,WAAY,CACZ,eAAgB,CAChB,gBAAiB,CACjB,YAAa,CACb,gBAAiB,CACjB,wBAAyB,CACzB,qBAAsB,CACtB,WAAY,CACZ,gBAAiB,CACjB,gBAAiB,CAZnB,gFAeI,0BAA2B,CAC3B,oCAA0C","sourcesContent":[".card {\n  position: relative;\n  max-width: 100%;\n  width: 250px;\n  min-width: 250px;\n  min-height: 350px;\n  display: flex;\n  flex-flow: column;\n  background-color: #ecf0f1;\n  border-radius: 0.25rem;\n  margin: 1rem;\n  user-select: none;\n  transition: 0.25s;\n  \n  &.float:hover {\n    transform: translateY(-8px);\n    box-shadow: 0 10px 10px -10px rgba(#7f8c8d, 1);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": "src-Card-styles-module__card--1s_T3",
	"float": "src-Card-styles-module__float--1OVtR"
};
export default ___CSS_LOADER_EXPORT___;
