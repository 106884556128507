// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-PaymentCard-Chip-styles-module__chip-container--4efQR{position:relative;display:flex;flex-flow:row;justify-content:flex-start;width:100%}.src-PaymentCard-Chip-styles-module__chip--2X2EL{position:relative;border-radius:0.5rem;width:3rem;height:2.25rem;background:linear-gradient(135deg, #ffb347, #fc3);border:2px solid rgba(44,62,80,0.2);margin-left:2rem;z-index:10}\n", "",{"version":3,"sources":["webpack://./src/PaymentCard/Chip/styles.module.scss"],"names":[],"mappings":"AAAA,2DACE,iBAAkB,CAClB,YAAa,CACb,aAAc,CACd,0BAA2B,CAC3B,UAAW,CACZ,iDAGC,iBAAkB,CAClB,oBAAqB,CACrB,UAAW,CACX,cAAe,CACf,iDAAqD,CACrD,mCAA8B,CAC9B,gBAAiB,CACjB,UAAW","sourcesContent":[".chip-container {\n  position: relative;\n  display: flex;\n  flex-flow: row;\n  justify-content: flex-start;\n  width: 100%;\n}\n\n.chip {\n  position: relative;\n  border-radius: 0.5rem;\n  width: 3rem;\n  height: 2.25rem;\n  background: linear-gradient(135deg, #ffb347, #ffcc33);\n  border: 2px solid rgba(#2c3e50, 0.2);\n  margin-left: 2rem;\n  z-index: 10;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"chip-container": "src-PaymentCard-Chip-styles-module__chip-container--4efQR",
	"chip": "src-PaymentCard-Chip-styles-module__chip--2X2EL"
};
export default ___CSS_LOADER_EXPORT___;
