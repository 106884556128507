// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "a.src-TaggedContentCard-styles-module__card-link--J20Pu{display:flex;text-decoration:none}.src-TaggedContentCard-styles-module__tagged-content-card--379ho{position:relative;display:flex;flex-flow:column;width:420px;height:350px;background-color:#2c3e50;transition:0.5s}.src-TaggedContentCard-styles-module__tagged-content-card--379ho:hover{transform:scale(1.1)}\n", "",{"version":3,"sources":["webpack://./src/TaggedContentCard/styles.module.scss"],"names":[],"mappings":"AAAA,wDACE,YAAa,CACb,oBAAqB,CACtB,iEAGC,iBAAkB,CAClB,YAAa,CACb,gBAAiB,CAEjB,WAAY,CACZ,YAAa,CAEb,wBAAyB,CACzB,eAAgB,CATlB,uEAYI,oBAAqB","sourcesContent":["a.card-link {\n  display: flex;\n  text-decoration: none;\n}\n\n.tagged-content-card {\n  position: relative;\n  display: flex;\n  flex-flow: column;\n\n  width: 420px;\n  height: 350px;\n\n  background-color: #2c3e50;\n  transition: 0.5s;\n  \n  &:hover {\n    transform: scale(1.1);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card-link": "src-TaggedContentCard-styles-module__card-link--J20Pu",
	"tagged-content-card": "src-TaggedContentCard-styles-module__tagged-content-card--379ho"
};
export default ___CSS_LOADER_EXPORT___;
