// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-ProductCard-ProductCardGallery-styles-module__product-card-gallery--ASc6G{position:relative;display:flex;flex-flow:row;flex:0 0 auto;height:50%;overflow:hidden}.src-ProductCard-ProductCardGallery-styles-module__product-card-gallery--ASc6G .src-ProductCard-ProductCardGallery-styles-module__gallery-item--354jK{position:absolute;width:100%;height:100%;background-size:cover;background-repeat:no-repeat;background-position:center;border-radius:0.25rem 0.25rem 0 0;opacity:0;transition:1s}.src-ProductCard-ProductCardGallery-styles-module__product-card-gallery--ASc6G .src-ProductCard-ProductCardGallery-styles-module__active--2lF3X{width:100%;opacity:1}\n", "",{"version":3,"sources":["webpack://./src/ProductCard/ProductCardGallery/styles.module.scss"],"names":[],"mappings":"AAAA,+EACE,iBAAkB,CAClB,YAAa,CACb,aAAc,CACd,aAAc,CACd,UAAW,CACX,eAAgB,CANlB,sJASI,iBAAkB,CAClB,UAAW,CACX,WAAW,CAEX,qBAAsB,CACtB,2BAA4B,CAC5B,0BAA2B,CAC3B,iCAAkC,CAClC,SAAU,CAEV,aAAc,CAnBlB,gJAsBI,UAAW,CACX,SAAU","sourcesContent":[".product-card-gallery {\n  position: relative;\n  display: flex;\n  flex-flow: row;\n  flex: 0 0 auto;\n  height: 50%;\n  overflow: hidden;\n\n  .gallery-item {\n    position: absolute;\n    width: 100%;\n    height:100%;\n\n    background-size: cover;\n    background-repeat: no-repeat;\n    background-position: center;\n    border-radius: 0.25rem 0.25rem 0 0;\n    opacity: 0;\n\n    transition: 1s;\n  }\n  .active {\n    width: 100%;\n    opacity: 1;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"product-card-gallery": "src-ProductCard-ProductCardGallery-styles-module__product-card-gallery--ASc6G",
	"gallery-item": "src-ProductCard-ProductCardGallery-styles-module__gallery-item--354jK",
	"active": "src-ProductCard-ProductCardGallery-styles-module__active--2lF3X"
};
export default ___CSS_LOADER_EXPORT___;
