// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-UserCard-UserCardBody-styles-module__user-card-body--1Ox5y{padding:1rem;padding-top:4rem}.src-UserCard-UserCardBody-styles-module__user-card-body--1Ox5y .src-UserCard-UserCardBody-styles-module__user-card-name--x7M0V{display:flex;justify-content:center;font-size:1.25rem;margin-bottom:0.25rem;color:#2c3e50}.src-UserCard-UserCardBody-styles-module__user-card-body--1Ox5y .src-UserCard-UserCardBody-styles-module__user-card-position-name--1MZBj{display:flex;justify-content:center;font-size:0.75rem;color:#7f8c8d}\n", "",{"version":3,"sources":["webpack://./src/UserCard/UserCardBody/styles.module.scss"],"names":[],"mappings":"AAAA,gEACE,YAAa,CACb,gBAAiB,CAFnB,gIAKI,YAAa,CACb,sBAAuB,CACvB,iBAAkB,CAClB,qBAAsB,CACtB,aAAc,CATlB,yIAaI,YAAa,CACb,sBAAuB,CACvB,iBAAkB,CAClB,aAAc","sourcesContent":[".user-card-body {\n  padding: 1rem;\n  padding-top: 4rem;\n\n  .user-card-name {\n    display: flex;\n    justify-content: center;\n    font-size: 1.25rem;\n    margin-bottom: 0.25rem;\n    color: #2c3e50;\n  }\n\n  .user-card-position-name {\n    display: flex;\n    justify-content: center;\n    font-size: 0.75rem;\n    color: #7f8c8d;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"user-card-body": "src-UserCard-UserCardBody-styles-module__user-card-body--1Ox5y",
	"user-card-name": "src-UserCard-UserCardBody-styles-module__user-card-name--x7M0V",
	"user-card-position-name": "src-UserCard-UserCardBody-styles-module__user-card-position-name--1MZBj"
};
export default ___CSS_LOADER_EXPORT___;
