import React from 'react';
import { GoogleLoginButton } from './GoogleLoginButton';

const Login = () => {
  return <div className='container'>
    <h1>Please Login</h1>
      <GoogleLoginButton />
  </div>;
};

export default Login;
