// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-ProductCard-PriceTag-styles-module__price-tag--2w4P4{position:absolute;background-color:rgba(44,62,80,0.95);color:#ecf0f1;border-radius:0.25rem;padding:0.125rem 0.5rem;top:1rem;left:1rem}\n", "",{"version":3,"sources":["webpack://./src/ProductCard/PriceTag/styles.module.scss"],"names":[],"mappings":"AAAA,0DACE,iBAAkB,CAClB,oCAA8B,CAC9B,aAAc,CACd,qBAAsB,CACtB,uBAAwB,CACxB,QAAS,CACT,SAAU","sourcesContent":[".price-tag {\n  position: absolute;\n  background-color: rgba(#2c3e50, 0.95);\n  color: #ecf0f1;\n  border-radius: 0.25rem;\n  padding: 0.125rem 0.5rem;\n  top: 1rem;\n  left: 1rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"price-tag": "src-ProductCard-PriceTag-styles-module__price-tag--2w4P4"
};
export default ___CSS_LOADER_EXPORT___;
