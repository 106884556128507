// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-FlippingCard-FlippingCardFront-styles-module__front--36kBs{position:absolute;width:100%;height:100%;backface-visibility:hidden;top:0;left:0;z-index:2;transform:rotateY(0deg);border-radius:0.25rem;overflow:hidden}\n", "",{"version":3,"sources":["webpack://./src/FlippingCard/FlippingCardFront/styles.module.scss"],"names":[],"mappings":"AAAA,gEACE,iBAAkB,CAClB,UAAW,CACX,WAAY,CAEZ,0BAA2B,CAC3B,KAAM,CACN,MAAO,CACP,SAAU,CACV,uBAAwB,CACxB,qBAAsB,CACtB,eAAgB","sourcesContent":[".front {\n  position: absolute;\n  width: 100%;\n  height: 100%;\n\n  backface-visibility: hidden;\n  top: 0;\n  left: 0;\n  z-index: 2;\n  transform: rotateY(0deg);\n  border-radius: 0.25rem;\n  overflow: hidden;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"front": "src-FlippingCard-FlippingCardFront-styles-module__front--36kBs"
};
export default ___CSS_LOADER_EXPORT___;
