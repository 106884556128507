// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-PaymentCard-PaymentCardName-styles-module__payment-card-name--1c9gq{position:relative;color:#ecf0f1;z-index:10;font-family:'OCR A Std';text-transform:uppercase;text-shadow:0px 1px 3px rgba(44,62,80,0.7);margin-left:1.3rem;margin-top:0.5rem;font-size:14px}\n", "",{"version":3,"sources":["webpack://./src/PaymentCard/PaymentCardName/styles.module.scss"],"names":[],"mappings":"AAAA,yEACE,iBAAkB,CAClB,aAAc,CACd,UAAW,CACX,uBAAwB,CACxB,wBAAyB,CACzB,0CAAqC,CACrC,kBAAmB,CACnB,iBAAkB,CAClB,cAAe","sourcesContent":[".payment-card-name {\n  position: relative;\n  color: #ecf0f1;\n  z-index: 10;\n  font-family: 'OCR A Std';\n  text-transform: uppercase;\n  text-shadow: 0px 1px 3px rgba(#2c3e50, 0.7);\n  margin-left: 1.3rem;\n  margin-top: 0.5rem;\n  font-size: 14px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"payment-card-name": "src-PaymentCard-PaymentCardName-styles-module__payment-card-name--1c9gq"
};
export default ___CSS_LOADER_EXPORT___;
