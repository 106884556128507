// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-UserCard-UserCardHeader-styles-module__user-card-header--QLwlN{position:relative;display:flex;flex:0 0 auto;height:50%;background-size:cover;background-repeat:no-repeat;background-position:center;border-radius:0.25rem 0.25rem 0 0}\n", "",{"version":3,"sources":["webpack://./src/UserCard/UserCardHeader/styles.module.scss"],"names":[],"mappings":"AAAA,oEACE,iBAAkB,CAClB,YAAa,CACb,aAAc,CACd,UAAW,CAEX,qBAAsB,CACtB,2BAA4B,CAC5B,0BAA2B,CAC3B,iCAAkC","sourcesContent":[".user-card-header {\n  position: relative;\n  display: flex;\n  flex: 0 0 auto;\n  height: 50%;\n\n  background-size: cover;\n  background-repeat: no-repeat;\n  background-position: center;\n  border-radius: 0.25rem 0.25rem 0 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"user-card-header": "src-UserCard-UserCardHeader-styles-module__user-card-header--QLwlN"
};
export default ___CSS_LOADER_EXPORT___;
