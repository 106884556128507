// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-FlippingCard-styles-module__flipping-card-container--3hXUC{perspective:1000px;background-color:transparent;transition:0.5s}.src-FlippingCard-styles-module__flipping-card-container--3hXUC:hover .src-FlippingCard-styles-module__flipping-card--Azf-r{transform:rotateY(180deg)}.src-FlippingCard-styles-module__flipping-card--Azf-r{position:absolute;width:100%;height:100%;perspective:1000px;transition:0.5s;transform-style:preserve-3d}\n", "",{"version":3,"sources":["webpack://./src/FlippingCard/styles.module.scss"],"names":[],"mappings":"AAAA,gEACE,kBAAmB,CACnB,4BAA6B,CAC7B,eAAgB,CAHlB,4HAOM,yBAA0B,CAC3B,sDAKH,iBAAkB,CAClB,UAAW,CACX,WAAY,CAEZ,kBAAmB,CACnB,eAAgB,CAChB,2BAA4B","sourcesContent":[".flipping-card-container {\n  perspective: 1000px;\n  background-color: transparent;\n  transition: 0.5s;\n  \n  &:hover {\n    .flipping-card {\n      transform: rotateY(180deg);\n    }\n  }\n}\n\n.flipping-card {\n  position: absolute;\n  width: 100%;\n  height: 100%;\n  \n  perspective: 1000px;\n  transition: 0.5s;\n  transform-style: preserve-3d;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"flipping-card-container": "src-FlippingCard-styles-module__flipping-card-container--3hXUC",
	"flipping-card": "src-FlippingCard-styles-module__flipping-card--Azf-r"
};
export default ___CSS_LOADER_EXPORT___;
