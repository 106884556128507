// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-UserCard-UserCardStats-styles-module__user-card-stats--16Ftx{display:flex;flex-flow:row;justify-content:center;font-size:0.75rem}.src-UserCard-UserCardStats-styles-module__stat-container--3AY5W{border-top:2px solid rgba(127,140,141,0.2);border-right:2px solid rgba(127,140,141,0.2);min-width:4rem;padding:0.25rem}.src-UserCard-UserCardStats-styles-module__stat-container--3AY5W:last-child{border-right:none}.src-UserCard-UserCardStats-styles-module__stat-name--1wZj5{display:flex;flex-flow:row;justify-content:center;color:#7f8c8d}.src-UserCard-UserCardStats-styles-module__stat-value--2jDYp{display:flex;flex-flow:row;justify-content:center;color:#2c3e50;font-weight:bold}\n", "",{"version":3,"sources":["webpack://./src/UserCard/UserCardStats/styles.module.scss"],"names":[],"mappings":"AAAA,kEACE,YAAa,CACb,aAAc,CACd,sBAAuB,CAEvB,iBAAkB,CACnB,iEAGC,0CAAkC,CAClC,4CAAoC,CACpC,cAAe,CACf,eAAgB,CAJlB,4EAOI,iBAAkB,CACnB,4DAID,YAAa,CACb,aAAc,CACd,sBAAuB,CACvB,aAAc,CACf,6DAGC,YAAa,CACb,aAAc,CACd,sBAAuB,CACvB,aAAc,CACd,gBAAiB","sourcesContent":[".user-card-stats {\n  display: flex;\n  flex-flow: row;\n  justify-content: center;\n\n  font-size: 0.75rem;\n}\n\n.stat-container {\n  border-top: 2px solid rgba(#7f8c8d, 0.2);\n  border-right: 2px solid rgba(#7f8c8d, 0.2);\n  min-width: 4rem;\n  padding: 0.25rem;\n\n  &:last-child {\n    border-right: none;\n  }\n}\n\n.stat-name {\n  display: flex;\n  flex-flow: row;\n  justify-content: center;\n  color: #7f8c8d;\n}\n\n.stat-value {\n  display: flex;\n  flex-flow: row;\n  justify-content: center;\n  color: #2c3e50;\n  font-weight: bold;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"user-card-stats": "src-UserCard-UserCardStats-styles-module__user-card-stats--16Ftx",
	"stat-container": "src-UserCard-UserCardStats-styles-module__stat-container--3AY5W",
	"stat-name": "src-UserCard-UserCardStats-styles-module__stat-name--1wZj5",
	"stat-value": "src-UserCard-UserCardStats-styles-module__stat-value--2jDYp"
};
export default ___CSS_LOADER_EXPORT___;
