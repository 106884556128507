// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-ProductCard-ProductCardGallery-GalleryToggle-styles-module__gallery-toggle--2guOL{position:absolute;display:flex;flex-flow:column;justify-content:center;align-items:center;height:100%;width:2rem;transition:0.25s;color:rgba(236,240,241,0.5);background-color:rgba(44,62,80,0.5);opacity:0;cursor:pointer}.src-ProductCard-ProductCardGallery-GalleryToggle-styles-module__gallery-toggle--2guOL.src-ProductCard-ProductCardGallery-GalleryToggle-styles-module__left--3XyKy{left:0;border-radius:0.25rem 0 0 0}.src-ProductCard-ProductCardGallery-GalleryToggle-styles-module__gallery-toggle--2guOL.src-ProductCard-ProductCardGallery-GalleryToggle-styles-module__right--1Ivut{right:0;border-radius:0 0.25rem 0 0}.src-ProductCard-ProductCardGallery-GalleryToggle-styles-module__gallery-toggle--2guOL:hover{opacity:1}\n", "",{"version":3,"sources":["webpack://./src/ProductCard/ProductCardGallery/GalleryToggle/styles.module.scss"],"names":[],"mappings":"AAAA,uFACE,iBAAkB,CAClB,YAAa,CACb,gBAAiB,CACjB,sBAAuB,CACvB,kBAAmB,CACnB,WAAY,CACZ,UAAW,CACX,gBAAiB,CACjB,2BAAmB,CACnB,mCAA8B,CAC9B,SAAU,CACV,cAAe,CAZjB,mKAeI,MAAO,CACP,2BAA4B,CAhBhC,oKAoBI,OAAQ,CACR,2BAA4B,CArBhC,6FAyBI,SAAU","sourcesContent":[".gallery-toggle {\n  position: absolute;\n  display: flex;\n  flex-flow: column;\n  justify-content: center;\n  align-items: center;\n  height: 100%;\n  width: 2rem;\n  transition: 0.25s;\n  color: rgba(#ecf0f1, 0.5);\n  background-color: rgba(#2c3e50, 0.5);\n  opacity: 0;\n  cursor: pointer;\n\n  &.left {\n    left: 0;\n    border-radius: 0.25rem 0 0 0;\n  }\n\n  &.right {\n    right: 0;\n    border-radius: 0 0.25rem 0 0;\n  }\n\n  &:hover {\n    opacity: 1;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"gallery-toggle": "src-ProductCard-ProductCardGallery-GalleryToggle-styles-module__gallery-toggle--2guOL",
	"left": "src-ProductCard-ProductCardGallery-GalleryToggle-styles-module__left--3XyKy",
	"right": "src-ProductCard-ProductCardGallery-GalleryToggle-styles-module__right--1Ivut"
};
export default ___CSS_LOADER_EXPORT___;
