// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-RecipeCard-RecipeCardContent-styles-module__recipe-card-content--3r09C{display:flex;flex-flow:column;justify-content:flex-start;align-items:center}.src-RecipeCard-RecipeCardContent-styles-module__recipe-card-content--3r09C .src-RecipeCard-RecipeCardContent-styles-module__thumbnail--2CvDu{display:flex;justify-content:center;align-items:center;width:100%}.src-RecipeCard-RecipeCardContent-styles-module__recipe-card-content--3r09C .src-RecipeCard-RecipeCardContent-styles-module__thumbnail--2CvDu div{width:100%;height:300px;background-size:cover;background-position:center}.src-RecipeCard-RecipeCardContent-styles-module__recipe-card-content--3r09C .src-RecipeCard-RecipeCardContent-styles-module__thumbnail--2CvDu div:hover{opacity:0.7}\n", "",{"version":3,"sources":["webpack://./src/RecipeCard/RecipeCardContent/styles.module.scss"],"names":[],"mappings":"AAAA,4EACE,YAAa,CACb,gBAAgB,CAChB,0BAA2B,CAC3B,kBAAmB,CAJrB,8IAOI,YAAY,CACZ,sBAAsB,CACtB,kBAAkB,CAClB,UAAW,CAVf,kJAaM,UAAU,CACV,YAAY,CACZ,qBAAsB,CACtB,0BAA2B,CAhBjC,wJAmBC,WAAW","sourcesContent":[".recipe-card-content{\n  display: flex;\n  flex-flow:column;\n  justify-content: flex-start;\n  align-items: center;\n\n  .thumbnail{\n    display:flex;\n    justify-content:center;\n    align-items:center;\n    width: 100%;\n    \n    div {\n      width:100%;\n      height:300px;\n      background-size: cover;\n      background-position: center;\n\n      &:hover{\n\topacity:0.7;\n      }\n    }\n  }\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"recipe-card-content": "src-RecipeCard-RecipeCardContent-styles-module__recipe-card-content--3r09C",
	"thumbnail": "src-RecipeCard-RecipeCardContent-styles-module__thumbnail--2CvDu"
};
export default ___CSS_LOADER_EXPORT___;
