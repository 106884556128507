// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-TaggedContentCard-CardOverlay-styles-module__card-overlay--3uIYF{position:absolute;width:100%;height:100%;overflow:hidden;border-radius:0.25rem}.src-TaggedContentCard-CardOverlay-styles-module__card-overlay--3uIYF .src-TaggedContentCard-CardOverlay-styles-module__overlay--1729F{position:absolute;width:100%;height:100%;background-repeat:no-repeat;background-position:center;background-size:cover;filter:blur(20px) brightness(0.75);backface-visibility:hidden;perspective:1000;transform:translate3d(0, 0, 0);transform:translateZ(0);will-change:transform}\n", "",{"version":3,"sources":["webpack://./src/TaggedContentCard/CardOverlay/styles.module.scss"],"names":[],"mappings":"AAAA,sEACE,iBAAkB,CAClB,UAAW,CACX,WAAY,CACZ,eAAgB,CAChB,qBAAsB,CALxB,uIAQI,iBAAkB,CAClB,UAAW,CACX,WAAY,CACZ,2BAA4B,CAC5B,0BAA2B,CAC3B,qBAAsB,CACtB,kCAAmC,CACnC,0BAA2B,CAC3B,gBAAiB,CACjB,8BAA6B,CAC7B,uBAAwB,CACxB,qBAAsB","sourcesContent":[".card-overlay {\n  position: absolute;\n  width: 100%;\n  height: 100%;\n  overflow: hidden;\n  border-radius: 0.25rem;\n\n  .overlay {\n    position: absolute;\n    width: 100%;\n    height: 100%;\n    background-repeat: no-repeat;\n    background-position: center;\n    background-size: cover;\n    filter: blur(20px) brightness(0.75);\n    backface-visibility: hidden;\n    perspective: 1000;\n    transform: translate3d(0,0,0);\n    transform: translateZ(0);\n    will-change: transform;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card-overlay": "src-TaggedContentCard-CardOverlay-styles-module__card-overlay--3uIYF",
	"overlay": "src-TaggedContentCard-CardOverlay-styles-module__overlay--1729F"
};
export default ___CSS_LOADER_EXPORT___;
